$modalBackgroundColor: #fff;

$inputColor: #1C2677;
$inputBackgroundColor: #F4F4F4;

$violetBlueColor: #2B5A64;

$buttonColor: #fff;
$buttonBackgroundColor: linear-gradient(90.13deg, #A42459 0%, #D35B00 100%);
$buttonModalBackgroundColor: linear-gradient(90.13deg, #A42459 0%, #D35B00 100%);
$buttonBackgroundColorHover: #fff;
$buttonBackgroundColorDisabled: linear-gradient(0deg, #19191926 0%, #19191926 100%); ;
$buttonTextColorDisabled: linear-gradient(0deg, #333333 0%, #333333 100%);

$profileButtonColor: linear-gradient(90.44deg, #47605C 0%, #47605C 100%);
$profileButtonActiveColor: #47605C;

$boxShadowGrey: rgba(197, 196, 208, 0.64);
$boxShadowPurple: rgba(84, 0, 114, 0.24);

$iconCrossSearchColor: #D35B00;
$iconResetPasswordBackgroundColor: #ffe2cc;
$iconResetPasswordFillColor: #D35B00;
$iconCoinsBalanceGradientColor: linear-gradient(90.13deg, #A42459 0%, #D35B00 100%);
$iconCoinsBalanceDefaultColor: #47605C;
$iconLikeWomanProfileFillColor: #D35B00;
$iconAddPlusFillColor: linear-gradient(90.5deg, #A42459 0%, #D35B00 100%);
$iconAddPhotoFillColor: #D35B00;
$iconVideosFillColor: #A42459;
$iconHandWaveFillColor: #A42459;
$iconLetterFillColor: #A42459;
$iconFilterFillColor: #2b5a64;
$iconLikeVideoComponentFillColor: #A42459;
$iconLetterVideoComponentFillColor: #A42459;
$iconMoveFillColor: #A42459;
$iconUploadAttachmentFillColor: #D35B00;
$iconArrowLeftFillColor: #D35B00;
$iconsMoreInfoChatHeaderFillColor: #D35B00;
$iconProfileFillColor: #2C5573;
$iconCrossCloseFillColor: #D35B00;
$iconReplyFillColor: #47605C;

$supportFeedbackModalHeaderBgColor: rgba(164, 36, 89, 0.1);
$supportFeedbackRadioLabelColor: #D35B00;
$supportFeedbackRadioLabelColorChecked: #A42459;
$supportFeedbackRadioLabelColorHover: #A424594D;
$supportFeedbackRatingTextColor: #8F2D4A;
$supportFeedbackTitleColor: #D35B00;
$supportFeedbackTextColor: #333333;
$supportFeedbackButtonBorderHover: double 1px transparent;

$mainFont: "Open Sans", sans-serif;
$greenColor: #1C9D53;
$whiteColor: #fff;
$greyColor: #4F4F4F;
$greyBlueColor: #426076;

$inputTextColor: #334B48;

$inputBorderNormal: #F1DDD2;
$inputBorderHover: #FF9E68;
$inputBorderActive: #FF9E68;

$selectBackgroundColor: #F8EFEB;
$selectBorderColor: #FF9E68;
$selectBorderHoverColor: #A42459;

$textareaBackgroundColor: #FFFFFF;
$textareaBackgroundHoverColor: #F5F7FA;
$textareaBackgroundActiveColor: #FFFFFF;
$textareaBorderColor: #F1DDD2;
$textareaBorderActiveColor: #D35B00;
$textareaOutlineColor: #D35B001a;

$messageRightBackground: #d1e2ee;
$messageLeftBackground: #F8EFEB;

$chatTextareaBg: #EBF2F7;

$attachTouchText: #A42459;

$bluePinkColor: #A42459;

$uploadTabsColor: #334B48;
$uploadTabsActiveColor: #A42459;

$uploadTabsItemBg: #E7EEF4;

$uploadTabsButton: #D35B00;

$mediaListTabActiveBg: #F8EFEB;
$mediaListTabActiveColor: #333;

$mediaListSwitcherActiveBg: #F8EFEB;

$searchBottomBtn: #A42459;

$buttonHoverBg: #F7E1D7;
$buttonHoverBg2: #F8EFEB;
$buttonHoverBg3: #F8EFEB;

$lettersMenuIconColor: #47605C;

$videoButtonsColor: #A42459;

$paymentBorderColor: rgba(68, 96, 119, 0.3);
$paymentBorderActiveColor: #798F8C;

$unreadLetter: #E8F1FA;

//LOGIN MODAL
$colorLoginForgetButton: #EB5757;
$colorLoginBottom: rgba(0, 0, 0, .2);
$colorLoginBottomSpan: #474F5C;

//ERROR MODAL
$colorErrorTitle: #00317B;
$colorErrorTitleHref: #1952ab;

//MAIN PAGE
$buttonArrowBackground: rgba(240, 249, 255, 0.9);

//ICONS

$c1ProfileIco: url(../../img/c1-profile_btn.svg);

$c3StickerViolet: url(../../img/c3-msg-sticker-violet.svg);
$c3StickerBlue: url(../../img/c3-msg-sticker-blue.svg);

$chatHello: url(../../img/chat-hello.svg);

$profileEdit: url(../../img/c3ph-edit.svg);
$profileEditH: url(../../img/c3ph-edit-h.svg);

$profileCredits: url(../../img/c3ph-purchase.svg);
$profileCreditsH: url(../../img/c3ph-purchase-h.svg);

$profileGifts: url(../../img/c3ph-gift.svg);
$profileGiftsH: url(../../img/c3ph-gift-h.svg);

$profileContact: url(../../img/c3ph-contact.svg);
$profileContactH: url(../../img/c3ph-contact-h.svg);

$profileMeeting: url(../../img/c3ph-meet.svg);
$profileMeetingH: url(../../img/c3ph-meet-h.svg);

$profileBlock: url(../../img/block-list-p.svg);
$profileBlockH: url(../../img/block-list-p-h.svg);

$profileLogout: url(../../img/logout-p.svg);

$editProfileBtn: url(../../img/edit-profile-btn.svg);

$profileLetter: url(../img/c3wpt_mail.svg);
$profileFavs: url(../img/c3wpt_favs.svg);
$profileComplain: url(../img/c3wpt_compl.svg);
$profileGiftTop: url(../img/c3wpt_gift.svg);

$messageStatusRead: url(../../img/readed.svg);
$messageStatusSend: url(../../img/send.svg);

$paymentSelectedCheck: url(../../../../img/check-circle-full-violet.svg);

$npsRadioLabelColor: #D35B00;
$npsRadioLabelColorChecked: #A42459;
$npsRadioLabelColorHover: #A424594D;
$npsButtonBorderHover: double 1px transparent;
$ratingTextColor: #333333;
$npsInputBorderColor: #F1DDD2;
$npsCheckboxColor: #D35B00;
$npsContactsInputColor: #333333;
$npsTextAreaBackgroundActiveColor: #F5F7FA;
$npsModalTitleColor: #D35B00;

//POLICY
$policyTitleColor: #D35B00;
$policyTextColor: #2B5A64;
$policyButtonBg: #FFEEE1;
$policyLinksColor: #0000FF;
$policyLinksHoverColor: #0101D6;
$policyScrollColor: #FCAE73;

@import 'theme';
