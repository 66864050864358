@import "@sitestyles";

.first_block_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../../img/auth/first_bg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1140px) {
    height: 360px;
    margin-top: 64px;
  }

  @media screen and (max-width: 536px) {
    height: 320px;
    background-image: url(../../img/auth/first_bg_mobile.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 64px;
  }
}

.first_block_bg:after {
  content: '';
  display: block;
  width: 100%;
  height: 450px;
  position: absolute;
  left: 0;
  bottom: -1px;
  background: $MainStartBlockShadow;
  @media screen and (max-width: 1140px) {
    height: 150px;
  }
}

.main_first_block {
  height: 100vh;
  max-height: 2000px;
  min-height: 850px;
  padding: 160px 0 54px;
  position: relative;
  background: #f8efeb;

  @media screen and (min-width: 1920px) {
    height: 1000px;
  }

  @media screen and (max-width: 1520px) {
    padding: 120px 0 54px;
  }

  @media screen and (max-width: 1440px) {
    padding: 140px 0 54px;
  }

  @media screen and (max-height: 750px) {
    padding: 94px 0 54px;
  }

  @media screen and (max-width: 1140px) {
    padding: 64px 0 32px;
    height: 100%;
    max-height: unset;
 }
}

.main_header_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
}

.main_header_left {
  flex: 1;
  display: flex;
  align-items: center;
}

.main_header_left_item {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $primaryColor;
  cursor: pointer;
}

.main_header_left_item:first-child {
  margin-right: 70px;
}

.main_header_logo {
  flex: 1;
}

.main_header_logo img {
  display: block;
  margin: 0 auto;
}

.main_header_login {
  flex: 1;
  display: flex;
}

.first_block_content {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: $mainTitleColor;

  @media screen and (min-width: 1680px) {
    padding-top: 80px;
  }

  @media screen and (min-width: 1920px) {
    padding-top: 100px;
  }
}

.main_header_login_btn img {
  margin-right: 15px;
}

.first_block_content_wrap {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: relative;
  @media screen and (max-width: 1520px) {
    padding-top: 45px;
  }
  @media screen and (max-width: 1380px) {
    padding-top: 0;
  }
  @media screen and (max-width: 1140px) {
    flex-direction: column;
  }
}

.first_block_text_wrap {
  text-align: center;
  align-self: flex-end;
  margin-left: 8px;

  @media screen and (max-width: 1920px) {
    max-width: 900px;
  }
  @media screen and (max-width: 1600px) {
    max-width: 690px;
  }
  @media screen and (max-width: 1480px) {
    max-width: 600px;
  }
  @media screen and (max-width: 1380px) {
    max-width: 700px;
  }
  @media screen and (max-width: 1140px) {
    align-self: center;
    margin-top: 258px;
    margin-bottom: 24px;
    max-width: 476px;
  }
  @media screen and (max-width: 536px) {
    max-width: 343px;
  }
  @media screen and (max-width: 370px) {
    max-width: 304px;
  }
}

.first_block_text_title {
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 10px;
  color: $mainTitleColor;
  @media screen and (max-width: 1140px) {
    font-size: 32px;
    line-height: 38px;
  }
  @media screen and (max-width: 536px) {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 12px;
  }
}

.first_block_text_content {
  font-weight: 500;
  font-size: 28px;
  color: $mainTextColor;
  @media screen and (max-width: 1140px) {
    font-size: 20px;
    line-height: 26px
  }
  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px
  }
}

.first_block_registr_wrap {
  width: 476px;
  background-color: $whiteColor;
  border-radius: 15px;
  padding: 32px 40px;
  @media screen and (max-width: 1140px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 536px) {
    width: 343px;
    max-width: 343px;
    padding: 24px 16px;
  }
  @media screen and (max-width: 370px) {
    width: 304px;
    max-width: 304px;
  }
}

.first_block_registr_title {
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
  @media screen and (max-width: 536px) {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}

.first_block_registr_form_row {
  position: relative;
}

.first_block_registr_form_text_row {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 5px;
  height: 63px;
  position: relative;
}

.first_block_registr_form_input {
  display: block;
  width: 100%;
  height: 36px;
  padding-left: 7px;
  font-weight: 600;
  font-size: 16px;
  color: $InputSelectedTextColor;

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #9F9F9F;
    font-family: $mainFont;
  }

  &:focus,
  &:active {
    border: 1px solid #9F9F9F;
    color: $greyColor;
    font-family: $mainFont;
  }

  &:-webkit-autofill {
    font-weight: 600 !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    color: $InputSelectedTextColor;
    -webkit-text-fill-color: $InputSelectedTextColor;
    -webkit-box-shadow: 0 0 0 1000px #F8F8F8 inset;
    font-weight: 600 !important;
    font-family: $mainFont;
    font-size: 16px;

    line-height: 22px;
  }
}


.first_block_registr_password_show {
  width: 24px;
  height: 24px;
  border: 0;
  outline: 0;
  background: url('../../img/password-show.svg') center no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.4);
  }

  &.active {
    background: url('../../img/password-hide.svg') center no-repeat;
    background-size: contain;
  }
}
.first_block_registr_form_password {
  padding-right: 50px;
}

.first_block_registr_form_row_line {
  height: 1px;
  width: 100%;
  background-color: $defaultTextColorAlpha3;
  border-radius: 4px;
}

.first_block_registr_form_label {
  position: absolute;
  left: 7px;
  bottom: 10px;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
  pointer-events: none;
  transition: .3s;
}

.first_block_registr_form_input:focus ~ .first_block_registr_form_label,
.first_block_registr_form_input:not(:placeholder-shown) ~ .first_block_registr_form_label {
  bottom: 41px;
  left: 0;
  font-size: 11px;
  color: $primaryColor;
}

.first_block_registr_form_dob_row {
  margin-top: 24px;
}

.first_block_registr_dob_title {
  font-weight: 600;
  color: $stormGreyColor;
  margin-bottom: 17px;
}

.first_block_registr_dob_row {
  display: flex;
  justify-content: space-between;
}

.main_select_item_wrap {
  position: relative;
  width: 30%;
  height: 42px;
}

.first_block_registr_dob_row .main_select_item_wrap:nth-child(2) {
  width: calc(40% - 16px);
  margin: 0 8px;
}

.main_select_item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.main_select_item_head {
  height: 42px;
  line-height: 40px;
  padding-left: 18px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
}

.main_select_item_head:after {
  content: '';
  display: block;
  width: 11px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  background-image: url(../../../../img/auth/main_select_arrow.svg);
  background-repeat: no-repeat;
  transition: .3s;
}

.main_select_item.open .main_select_item_head:after {
  transform: translateY(-50%) rotate(180deg);
}

.main_select_item_head_line {
  width: calc(100% + 18px);
  height: 1px;
  background-color: $defaultTextColorAlpha3;
  box-shadow: 0px 2px 10px rgba(84, 0, 114, 0.05);
  border-radius: 4px;
  margin-left: -18px;
}

.main_select_item_list {
  background-color: $whiteColor;
  display: none;
  border: 1px solid $silverColor;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  margin-top: -1px;
  padding: 5px 0;
}

.main_select_item_list_inner {
  margin-right: 3px;
  max-height: 172px;
  overflow-y: auto;
}

.main_select_item_list_inner::-webkit-scrollbar {
  width: 3px;
  background: $whiteColor;
  border-radius: 3px;
}

.main_select_item_list_inner::-webkit-scrollbar-thumb {
  background: $michiganSkyColor;
  border-radius: 3px;
}

.main_select_item_list input {
  display: none;
}

.main_select_item_list label {
  display: block;
  margin: 0 5px;
  padding: 13px 13px 11px;
  cursor: pointer;
  font-size: 16px;
  color: $darkerIndigoTemplateColor;
}

.main_select_item_list label:hover {
  background: linear-gradient(0deg, $yourPaleColor, $yourPaleColor), $whiteColorAlpha8;
  border-radius: 5px;
  font-weight: 500;
  color: $primaryColor;
}

.main_select_item.open {
  z-index: 3;
}

.main_select_item.open .main_select_item_list {
  display: block;
}

.main_select_item.selected .main_select_item_head {
  font-weight: 600;
  color: $unknownSenSmBLPaAColor;
}

.first_block_registr_form_looking_title {
  font-weight: 600;
  color: $stormGreyColor;
}

.first_block_registr_form_looking_row .main_select_item_wrap {
  flex: 1;
  margin-left: 22px;
}

.first_block_registr_form_policy_row {
  display: flex;
  align-items: center;
  margin-top: 22px;
}

.first_block_registr_form_policy_row input {
  display: none;
}

.first_block_registr_form_policy_row label {
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid $unknownSenSmBLPaAColor;
  border-radius: 100%;
  flex: none;
  margin-right: 13px;
  position: relative;
  cursor: pointer;
}

.first_block_registr_form_policy_row input:checked + label:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: $unknownSenSmBLPaAColor;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.first_block_registr_form_policy_text {
  font-size: 12px;
  line-height: 1.5;
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first_block_registr_form_policy_text div {
  display: inline;
  cursor: pointer;
}

.first_block_registr_form_policy_text span {
  background: $blackColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.first_block_registr_signup_hr {
  display: flex;
  align-items: center;
  margin-top: 29px;
}

.first_block_registr_signup_line {
  height: 1px;
  flex: 1;
  background-color: $greyColorBorderAlpha2;
}

.first_block_registr_signup_text {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: $stormGreyColor;
  margin: 0 20px;
}

.first_block_registr_signup_socials {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.first_block_registr_signup_socials_item {
  margin: 0 7px;
}

/********
sign in styles start
********/

.first_block_sign_forms_wrap {
  position: relative;
}

.first_block_sign_forms_wrap.sign_in {

  .first_block_sign_reg_wrap {
    @media screen and (min-width: 1100px) {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
    @media screen and (max-width: 1099px) {
      display: none;
    }
  }


  .first_block_sign_in_wrap {
    @media screen and (min-width: 1100px) {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
    @media screen and (max-width: 1099px) {
      display: block;
    }
  }
}

.first_block_reg_top_sign {
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  margin-bottom: 16px;
  border: 1px solid #e0e0e0;

  @media screen and (max-width: 1449px) {
    height: 60px;
  }

  @media screen and (max-width: 1099px) and (min-width: 600px) {
    width: 585px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 449px) {
    height: 50px;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: space-around;
  }

  .reg_top_sign_text {
    font-size: 16px;
    font-weight: 600;
    margin-right: 47px;

    @media screen and (max-width: 1449px) {
      font-size: 14px;
      margin-right: 30px;
    }

    @media screen and (max-width: 449px) {
      margin-right: 0;
      font-size: 12px;
    }
  }

  .reg_top_sign_btn {
    width: 146px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $buttonBackgroundColor;
    color: #fff;
    border-radius: 30px;
    cursor: pointer;

    @media screen and (max-width: 1449px) {
      width: 110px;
      height: 36px;
      font-size: 14px;
    }

    @media screen and (max-width: 449px) {
      width: 90px;
      height: 32px;
      font-size: 12px;
    }

    img {
      margin-right: 16px;

      @media screen and (max-width: 1449px) {
        width: 16px;
        margin-right: 12px;
      }

      @media screen and (max-width: 449px) {
        width: 14px;
        margin-right: 8px;
      }
    }
  }
}

.first_block_sign_in_wrap {
  background-color: #fff;
  border-radius: 15px;
  padding: 56px 40px;
  width: 476px;
  box-sizing: border-box;
  max-width: 476px;

  @media screen and (min-width: 1100px) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  @media screen and (max-width: 1099px) {
    display: none;
  }

  @media screen and (max-width: 1140px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 536px) {
    width: 343px;
    max-width: 343px;
    padding: 24px 16px;
  }
  @media screen and (max-width: 370px) {
    width: 304px;
    max-width: 304px;
  }
}

.first_block_registr_form_find_btn {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $whiteColor;
  margin-top: 24px;
  cursor: pointer;
  font-family: $mainFont;
  position: relative;
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: double 1px transparent;
  border-radius: 5px;
  background-image: $buttonBackgroundColor, linear-gradient(to right, $accentLeftColor, $accentRightColor);

  &:hover {
    background-image: linear-gradient(white, white), linear-gradient(to right, $accentLeftColor, $accentRightColor);

    .first_block_registr_form_find_btn_text {
      background: $buttonBackgroundColor;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.first_block_registr_form_find_btn_text {
  background: #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #FFFFFF;
  background-clip: text;
  text-fill-color: #FFFFFF;
  font-weight: 700;
}

.registr_form_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.registr_form_footer_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $greyColor;
  margin-right: 16px;
  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px;
    margin-right: 12px;
  }
}

.registr_form_footer_btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  font-family: $mainFont;
  cursor: pointer;

  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.registr_form_footer_btn:hover .registr_form_footer_btn_text {
  background: $mainButtonHoverBg;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.registr_form_footer_btn_text {
  background: $buttonBackgroundColor;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
}

.first_block_registr_form_text_fieldset {
  display: grid;
  grid-row-gap: 12px;
}

.first_block_sign_in_reset_btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: $mainFont;
  text-align: center;
  color: #9F9F9F;
  cursor: pointer;
  margin-top: 8px;

  &:hover {
    color: $InputSelectedTextColor;
  }
}

.first_block_sign_in_or_lines {
  display: flex;
  align-items: center;
  margin: 24px 0 16px;

  @media screen and (max-width: 599px) {
    margin: 12px 0;
  }

  .line {
    flex: 1;
    height: 1px;
    background-color: rgba(51, 51, 51, .3);
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #CCCCCC;
    margin: 0 8px 2px;
  }
}

.first_block_sign_in_create_btn {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #BE527F;
  color: #BE527F;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;

  @media screen and (max-width: 1099px) and (min-width: 600px) {
    width: 328px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 599px) {
    width: 286px;
    height: 35px;
    max-width: 100%;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
  }
}