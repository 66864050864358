@import "@sitestyles";

.main_info_block {
  padding-bottom: 150px;
  background: #f8efeb;
  @media screen and (max-width: 1024px) {
    padding-bottom: 64px;
  }
}

.main_info_block_list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  justify-content: center;
  row-gap: 150px;
  @media screen and (max-width: 1400px) {
    row-gap: 120px;
  }
  @media screen and (max-width: 1024px) {
    row-gap: 60px;
  }
}

.main_info_block_item {
  width: 387px;
  max-width: 387px;
  text-align: center;
  flex: 1 22.5%;
  @media screen and (max-width: 1700px) {
    flex: 1 30%;
  }
  @media screen and (max-width: 1280px) {
    flex: 1 47.5%;
  }
  @media screen and (max-width: 870px) {
    width: 288px;
    max-width: 288px;
  }
  @media screen and (max-width: 670px) {
    flex: 1 100%;

  }
}

.main_info_block_item_ico {
  width: 100px;
  height: 100px;
  object-fit: contain;
  display: block;
  margin: 0 auto 48px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 24px;
  }
}

.main_info_block_item_title {
  font-weight: 600;
  font-size: 24px;
  color: $unknownSenSmBLPaAColor;
  margin-bottom: 8px;
  @media screen and (max-width: 536px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.main_info_block_item_text {
  font-size: 16px;
  line-height: 22px;
  color: rgba(79, 79, 79, 0.8);
  @media screen and (max-width: 536px) {
    font-size: 14px;
    line-height: 19px;
  }
}